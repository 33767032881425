<template>
  <basic-container>
    <el-container>
      <CommonTree
          class="CommonTree"
          style="flex-shrink: 0;font-size: 14px"
          :treeData="dataParent"
          :defaultProps="defaultProps"
          :searchTitle="searchChange"
          :isShowdig="true"
          :showCheckbox="false"
          :popover-show="false"
          :expandOnClickNode="false"
          treeTitle="字典列表"
          @include-down="onLoadParent"
          @getTreeAdd="getNew"
          @getTreeEdit="rowUpdate"
          @getTreeDelete="rowDel"
          @getNodeClick="handleRowClick"
      />
      <el-main>
        <dict-biz-config ref="dictBizConfig"></dict-biz-config>
      </el-main>
    <CommonDialog
        v-if="isShow"
        dialogTitle="业务字典编辑"
        width="60%"
        @cancel="isShow = false"
        @confirm="rowDict"
    >
      <dict-biz-edit ref="dictBizEdit" :dataObj="dataObj" @cancel="cancel"></dict-biz-edit>
    </CommonDialog>
    </el-container>
  </basic-container>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {
  getParentList,
  getChildList,
  remove,
  update,
  add,
  getDict,
  getDictTree
} from "@/api/system/dictbiz";
import { optionParent, optionChild } from "@/option/system/dictbiz";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "../components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import dictBizConfig from "@/views/system/dictbizConfig.vue";
import {$t} from "@/lang";
import CommonDialog from "@/components/CommonDialog/index.vue";
import dictBizEdit from "@/views/system/dictbizEdit.vue";
import dictEdit from "@/views/system/dictEdit.vue";
import CommonTree from "@/views/components/com_tree/index.vue";
export default {
  components: {
    CommonTree,
    dictEdit,
    CommonDialog,
    HeadLayout,
    GridLayout,
    formLayout,
    dialogHeadBtn,
    dictBizConfig,
    dictBizEdit
  },
  data() {
    return {
      defaultProps: {
        label: "label",
        value: "id",
        key: "code",
      },
      isShow: false,
      dataObj: {},
      searchForm: {},
      searchColumns: [
        {
          label: "",
          prop: "code",
          search: true,
         placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.dict.field.code"),
          span:4,
        },

        {
          label: "",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.dict.field.dictValue"),
          prop: "dictValue",
          search: true,
          span:4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.dict.field.isSealed"),
          prop: "isSealed",
          type: "select",
          row:true,
          align: "center",
          width: 100,
          span:4,
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          value: 0,
          // slot: true,
          rules: [
            {
              required: true,
              message: $t('cip.cmn.rule.selectWarning') + $t('cip.plat.sys.dict.field.isSealed'),
              trigger: "blur"
            }
          ]
        },
      ],
      dictValue: '暂无',
      parentId: -1,
      formParent: {},
      formChild: {},
      selectionList: [],
      query: {},
      box: false,
      loading: true,
      loadingChild: true,
      // pageParent: {
      //   pageSize: 20,
      //   pageSizes: [10, 20,30, 40, 50, 100],
      //   currentPage: 1,
      //   total: 0
      // },
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      dataTotal:0,
      pageParent: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        pageSizes: [10, 20,30, 40, 50, 100],
        currentPage: 1,
        total: 0
      },
      pageChild: {
        pageSize: 20,
        pageSizes: [10, 20,30, 40, 50, 100],
        currentPage: 1,
        total: 0
      },
      dataParent: [],
      dataChild: [],
      optionParent: optionParent(this),
      optionChild: optionChild(this),
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dictbiz_add, false),
        delBtn: this.vaildData(this.permission.dictbiz_delete, false),
        editBtn: this.vaildData(this.permission.dictbiz_edit, false),
        viewBtn: false,
      };
    },
    headBtnOptions() {
      let   buttonBtn = [];
      if (this.permission.dictbiz_add) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.addBtn'),
            emit: "head-add",
            type: "button",
            icon: ""
          });
      }
      if (this.permission.dictbiz_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "head-romve",
            type: "button",
            icon: ""
          });
      }
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let   buttonBtn = [];
      if (this.permission.dictbiz_edit) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "grid-edit",
            type: "text",
            icon: ""
          });
      }
      if (this.permission.dictbiz_dicConfig) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.dicConfigBtn'),
            emit: "grid-config",
            type: "text",
            icon: ""
          })
      }

      if (this.permission.dictbiz_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "grid-romve",
            type: "text",
            icon: ""
          })
      }
      return buttonBtn;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  created() {
    this.optionParent.viewBtn = false
    this.optionParent.searchShow = false
    this.onLoadParent(this.page)
  },
  mounted() {
    this.initData();
  },
  methods: {
    handleRowClick(row) {
      this.$refs.dictBizConfig.getData(row)
    },
    rowDict(){
      this.$refs.dictBizEdit.headSave('save');
    },
     getNew() {
      this.dataObj = {}
       this.isShow = true;
    },
    save() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then(() => {
            this.onLoadParent(this.page);
            this.dataObj = ''
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            this.isShow = false

          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false;
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false;
        }
      })

    },
    cancel() {
      this.isShow = false;
      this.dataObj = '';
      this.onLoadParent(this.pageParent)
    },
    initData() {
      getDictTree().then(res => {
        const column = this.findObject(this.optionChild.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    handleAdd(row) {
      this.formChild.dictValue = "";
      this.formChild.dictKey = "";
      this.formChild.sort = 0;
      this.formChild.isSealed = 0;
      this.formChild.remark = "";
      this.formChild.parentId = row.id;
      this.$refs.crudChild.rowAdd();
    },
    rowSave(row, done, loading) {
      const form = {
        ...row,
        dictKey: -1,
      };
      add(form).then(() => {
        this.onLoadParent(this.page);
          this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });

      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row) {
      this.isShow = true
      this.dataObj = row
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoadParent(this.page);
          this.$message({
             type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoadParent(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoadParent(this.page, params);

    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoadParent(this.page);
          this.$message({
             type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDict(this.formParent.id).then(res => {
          this.formParent = res.data.data;
        });
      }

    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoadParent(this.page, this.query);
    },
    rowSaveChild(row, done, loading) {
      add(row).then(() => {
        this.onLoadChild(this.pageChild);
          this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });

      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdateChild(row, index, done, loading) {
      update(row).then(() => {
        this.onLoadChild(this.pageChild);
          this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });

      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowDelChild(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoadChild(this.pageChild);
          this.$message({
             type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    searchResetChild() {
      this.query = {};
      this.onLoadChild(this.pageChild);
    },
    searchChangeChild(params, done) {
      this.query = params;
      this.pageChild.currentPage = 1;
      this.onLoadChild(this.pageChild, params);

    },
    selectionChangeChild(list) {
      this.selectionList = list;
    },
    selectionClearChild() {
      this.selectionList = [];
      this.$refs.crudChild.toggleSelection();
    },
    handleDeleteChild() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoadChild(this.pageChild);
          this.$message({
             type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crudChild.toggleSelection();
        });
    },
    beforeOpenChild(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getDict(this.formChild.id).then(res => {
          this.formChild = res.data.data;
        });
      }

    },
    beforeCloseChild(done) {
      this.$refs.crudChild.value.parentId = this.parentId;
      this.$refs.crudChild.option.column.filter(item => {
        if (item.prop === "parentId") {
          item.value = this.parentId;
        }
      });

    },
    currentChangeChild(currentPage) {
      this.pageChild.currentPage = currentPage;
    },
    sizeChangeChild(pageSize) {
      this.pageChild.pageSize = pageSize;
    },
    refreshChangeChild() {
      this.onLoadChild(this.pageChild, this.query);
    },
    onLoadParent(page, params = {}) {
      this.page = page;
      this.loading = true;
      getParentList(
        1,
        999,
        Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        // this.$refs.gridLayOut.page.total = data.total;
        this.dataParent = data.records;
        this.dataParent = data.records.map(record => {
          return {
            ...record, // 保留原有的属性
            label: record.dictValue+"【"+ record.code+"】"// 添加临时字段
          };
        });
        this.loading = false;
        this.selectionClear();
      });
    },
    onLoadChild(page, params = {}) {
      this.loadingChild = true;
      getChildList(
        page.currentPage,
        page.pageSize,
        this.parentId,
        Object.assign(params, this.query)
      ).then(res => {
        this.dataChild = res.data.data;
        this.loadingChild = false;
        this.selectionClear();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.CommonTree ::v-deep .el-tree{
  height: calc(100vh - 200px);
}
</style>
