var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            staticClass: "CommonTree",
            staticStyle: { "flex-shrink": "0", "font-size": "14px" },
            attrs: {
              treeData: _vm.dataParent,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchChange,
              isShowdig: true,
              showCheckbox: false,
              "popover-show": false,
              expandOnClickNode: false,
              treeTitle: "字典列表",
            },
            on: {
              "include-down": _vm.onLoadParent,
              getTreeAdd: _vm.getNew,
              getTreeEdit: _vm.rowUpdate,
              getTreeDelete: _vm.rowDel,
              getNodeClick: _vm.handleRowClick,
            },
          }),
          _c("el-main", [_c("dict-biz-config", { ref: "dictBizConfig" })], 1),
          _vm.isShow
            ? _c(
                "CommonDialog",
                {
                  attrs: { dialogTitle: "业务字典编辑", width: "60%" },
                  on: {
                    cancel: function ($event) {
                      _vm.isShow = false
                    },
                    confirm: _vm.rowDict,
                  },
                },
                [
                  _c("dict-biz-edit", {
                    ref: "dictBizEdit",
                    attrs: { dataObj: _vm.dataObj },
                    on: { cancel: _vm.cancel },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }